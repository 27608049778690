export default { 
    CrownAndBridge: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'fd51f965-142a-43ea-bd6c-c6c6a7b7922e' : 'fd51f965-142a-43ea-bd6c-c6c6a7b7922e',
    FCZ: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'ae1a0430-94b0-4a40-bb9c-a380a82a183e' : 'ae1a0430-94b0-4a40-bb9c-a380a82a183e',
    PFZ: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '54d064ad-c9e0-4a08-bcd1-e11847b6927e' : '54d064ad-c9e0-4a08-bcd1-e11847b6927e',
    CASTS: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'bdbf7f51-3be7-4f48-a629-b58fc7948907' : '8b0a8e05-e6e3-4120-a371-38741051313f',
    FACIALREFERENCEDIGITALMOUNTINGMODELS: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '2b5ffbf8-555c-4a3d-bd61-7a836e37fcb6' : '2b5ffbf8-555c-4a3d-bd61-7a836e37fcb6',
    NESTINGFILE: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'f5b1e6ad-52c0-444b-9ea5-8228d778c8eb' : 'f5b1e6ad-52c0-444b-9ea5-8228d778c8eb',
    IMPLANTCROWN: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '5f9548ac-d468-4346-91fe-4be6579488be' : '7fd1e094-1e65-4951-9aff-4d0c5075be3f',
    ALLONX: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '22f99fda-9993-4954-bea9-f04ab423e78f' : '88a1ade1-016a-47a4-890e-a2bec7743165',
    ALLONXPACKAGE: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '0f6e91bf-171e-4a14-8e30-b6181f8735e1' : '0f6e91bf-171e-4a14-8e30-b6181f8735e1',
    RUSH: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'a23f49df-4156-4311-b363-63e6bd37dafd' : '55d25cb2-4166-40ec-b91f-8e9c12973ea4',
    PHOTOCASEPRESENTATION: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'f7569666-8956-4dee-bc20-596c748dba11' : 'c350f394-54bd-42f3-8d78-41d7f8b99604',
    DENTOFACIALANALYSIS: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '3951cfbe-81e4-4e78-9870-b27b2a683d52' : '4828c26c-eb01-4ccc-a808-d8aed580ae33',
    DEVICES: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '7b825182-e2d7-4eeb-8162-9891c0a75a05' : '802f21bd-2cc6-4788-90b7-df3efcb489de'
}